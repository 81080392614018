import {FC,useState} from 'react';
import Modal from '../../../../common/modal';
import {ModalRow, SubmitButton} from './styles';
import InputWithLabel from '../../../../common/components_for_modals/input_with_label';
import useFetch from '../../../../hooks/useFetch';
import { IUser } from '../../../../types/global_types';
import inputsHandler from '../../../../utils/inputsHandler';
import Loader from '../../../../common/loader';
import getAccessToken from '../../../../utils/getAccessToken';
import reloadWindow from '../../../../utils/reloadWindow';

interface Props {
    onClose: () => void;
    user: IUser
}

const UpdateUserModal: FC<Props> = ({onClose,user}) => {
    const [userData,setUserData] = useState<IUser>({...user})

    const {loading,dataHandler} = useFetch(`user/edit/${user._id}`,
    {method:'PUT',headers:{'Content-Type':'application/json','Authorization': `Bearer ${getAccessToken()}`},body:JSON.stringify(userData)},
    true
    )

    const confirmUpdate = async () => {
        if(userData.password.length < 5){
            alert('Password must have at least 4 characters')
            return
        }
        await dataHandler()
        onClose()
        reloadWindow()  
    }

    if(loading){
        return <Loader/>
    }
    
    return (
        <Modal
            title='Update user'
            onClose={onClose}
            variant='small'
        >
            <ModalRow>
                <InputWithLabel
                    label='Email'
                    name={'email'}
                    value={userData.email}
                    onChange={(value,name) => inputsHandler('email',value,setUserData)}
                />
            </ModalRow>
            <ModalRow>
                <InputWithLabel
                    label='Wallet address'
                    name={'wallet'}
                    value={userData.wallet}
                    onChange={(value) => inputsHandler('wallet',value,setUserData)}
                />
            </ModalRow>
            <ModalRow>
                <InputWithLabel
                    label='Username'
                    value={userData.username || ''}
                    onChange={(value) => inputsHandler('username',value,setUserData)}
                />
            </ModalRow>
            <SubmitButton onClick={confirmUpdate} type='fill'>
                Save changes
            </SubmitButton>
        </Modal>
    );
};

export default UpdateUserModal;