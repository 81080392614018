import React from 'react';
import SearchIcon from '../../../../common/Icons/search_icon';
import Filter from '../../../../common/filter';
import SortBy from '../../../../common/sort_by';
import {useStyles} from './styles';

const Header = () => {
    const {
        wrapper,
        leftWrapper,
        mainTitle,
        searchWrapper,
        searchInput,
    } = useStyles()

    return (
        <div className={wrapper}>
            <div className={leftWrapper}>
                <h1 className={mainTitle}>
                    OTC Deals
                </h1>
                <div className={searchWrapper}>
                    <SearchIcon />
                    <input
                        type="text"
                        placeholder='Search'
                        className={searchInput}
                    />
                </div>
                <Filter />
                <SortBy />
            </div>
        </div>
    );
};

export default Header;