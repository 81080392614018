import {createUseStyles} from 'react-jss';

export const useStyles = createUseStyles({
    wrapper: {
        width:'95%',
        margin:'40px auto'
    },
    rows:{
    },
    subTitle:{
        fontWeight:600,
        marginBottom:'12px'
    },
    itemsWrapper:{
        marginTop:'15px',

    },
    members:{
        display:'flex',
        flexWrap:'wrap',
        gap:20
    },
    title:{
    }
})