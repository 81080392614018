import React, {useState} from 'react';
import {useStyles} from './styles';
import avatarImage from '../../../../../../assets/img/avatar.png'
import CopyIcon from '../../../../../common/Icons/copy_icon';
import {USERS_STATUS_LIST} from '../../../../../../static_content/dropdowns_data';
import Button from '../../../../../common/button';
import EditIcon from '../../../../../common/Icons/edit_icon';
import UserStatusDropdown from '../../../../../common/users_status_dropdown';
import DotsButtonWithDropdown from '../../../../../common/dots_button_with_dropdown';
import {Link} from 'react-router-dom';
import { IDeal } from '../../../../../types/global_types';
import loader from '../../../../../services/loader';
import sliceAddress from '../../../../../utils/sliceAddress';

const walletKey = '0xds4f54df4sd5654654d6s54f6s'

const TableRow = ({confirmCompleteDeal,deal}: {confirmCompleteDeal:any;deal:IDeal}) => {
    const {
        wrapper,
        rowWrapper,
        pointsWrapper,
        statusWrapper,
        walletWrapper,
        userWrapper,
        dataWrapper,
        actionsWrapper,
        reactionButton,
    } = useStyles()

    const [activeStatus, setActiveStatus] = useState<USERS_STATUS_LIST>(USERS_STATUS_LIST.ACTIVE)

    const copyHandle = (value: string) => {
        navigator.clipboard.writeText(value)
    }

    return (
        <div className={wrapper}>
            <div className={`container ${rowWrapper}`}>
                <div className={dataWrapper}>
                    <Link to='users_list/123' className={userWrapper}>
                        <img src={
                            deal.creator?.photo
                            ?
                            loader(deal.creator?.photo)
                            :
                            deal.creator?.twitterData?.photo
                        } alt={deal.creator?.username || ''}/>
                        <p>{deal.creator?.username || deal?.creator?.twitterData?.username}</p>
                    </Link>
                    {
                        deal?.buyer
                        ?
                        <Link to='users_list/123' className={userWrapper}>
                        <img src={
                            deal?.buyer?.photo
                            ?
                            loader(deal.buyer?.photo)
                            :
                            deal.buyer?.twitterData?.photo
                        } alt={deal.buyer?.username || ''}/>
                        <p>{deal.buyer?.username || deal?.buyer?.twitterData?.username}</p>
                    </Link>
                    :
                    <div className={userWrapper}>
                        -
                    </div>
                    }
                    <div
                        className={walletWrapper}
                        onClick={() => copyHandle(walletKey)}
                    >
                        {sliceAddress(deal.creator.wallet)}
                        <CopyIcon />
                    </div>
                    <div className={statusWrapper}>
                        <UserStatusDropdown
                            onChange={(value) => setActiveStatus(value)}
                            activeOption={activeStatus}
                        />
                    </div>
                    <div className={pointsWrapper}>
                        {deal.price}{deal.ticker === 'ETH' ? ' ETH' : '$'}
                    </div>
                    <div className={pointsWrapper}>
                        {deal.name}
                    </div>
                </div>
                <div className={actionsWrapper}>
                    <button className={`${reactionButton} checked`}>
                        👍 0
                    </button>
                    <button className={`${reactionButton}`}>
                        👎 0
                    </button>
                    {/* <Button
                        onClick={updateUserData}
                        type='outlined'
                    >
                        <EditIcon />
                    </Button> */}
                    <DotsButtonWithDropdown items={[
                        {title: 'Complete deal', onClick: () => confirmCompleteDeal(deal,false)},
                        {title: 'Complete deal with a refund', onClick: () => confirmCompleteDeal(deal,true)}
                    ]} />
                </div>
            </div>
        </div>
    );
};

export default TableRow;