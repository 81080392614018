import { IProject } from '../../hooks/useCreateProject';
import getAccessToken from '../../utils/getAccessToken';
import { configureUrl } from '../config';

export default async (type:string,status?:string,sortValue?:any) : Promise<{isSuccess:boolean,projects:Array<IProject>}> => {
    try{
        const accessToken : string | null = getAccessToken() 

        let path = `${type}`

        if(status) path = path + `?status=${status}`
        if(sortValue) path = path + `&sort=${sortValue}`

        const res = await fetch(configureUrl(`projects/${path}`),{
            method:'GET',
            headers:{
                'Authorization':`Bearer ${accessToken}`,
            },
        })

        const data = await res.json()

        return {isSuccess:res.status < 300,projects:data || []}
    }catch(error){
        console.log(error)

        return {isSuccess:false,projects:[]}
    }
}   